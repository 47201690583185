<template>
    <div class="zykt-main p-1 h-100 box-border">
        <el-card class="no-head zy-card mb-1">
            <el-row :gutter="10">
                <el-col :span="8">
                    <div class="font-16 global-head po-relative pl-1 mb-2">
                        修改单价
                    </div>

                    <div class="global-body pl-2">
                        <!-- <div class="d-flex ai-center mb-1">
                            <div class="font-14">
                                季节切换：
                            </div>
                            <el-select
                                class="mr-1"
                                v-model="searchModel.summer"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in seasonSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div> -->

                        <div class="d-flex ai-center mb-1">
                            <div class="font-14">
                                当前单价：
                            </div>
                            <el-input
                                style="width:210px"
                                v-model="pice"
                                disabled
                                class="mr-1"
                            ></el-input>
                            元/当量
                        </div>

                        <div class="d-flex ai-center mb-1">
                            <div class="font-14">
                                修改单价：
                            </div>
                            <el-input
                                style="width:210px"
                                class="mr-1"
                                v-model="searchModel.unitprice"
                                placeholder="请输入"
                            >
                            </el-input>
                            元/当量
                        </div>

                        <el-button type="primary" @click="setupBtn">
                            设置
                        </el-button>
                    </div>
                </el-col>

                <el-col :span="8">
                    <div class="font-16 global-head po-relative pl-1 mb-2">
                        切换季节
                    </div>

                    <div class="global-body pl-2">
                        <div class="d-flex ai-center mb-1">
                            <div class="font-14">
                                当前模式：
                            </div>
                            <el-input
                                style="width:210px"
                                v-model="season"
                                disabled
                                class="mr-1"
                            ></el-input>
                        </div>
                        <div class="d-flex ai-center mb-1">
                            <div class="font-14">
                                切换模式：
                            </div>

                            <el-select
                                class="mr-1"
                                v-model="seasonModel"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in switchSeason"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>

                        <el-button type="primary" @click="switchBtn"
                            >设置</el-button
                        >
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <el-card class="no-head zy-card mb-1">
            <!--<el-card class="zy-card mb-1">
                <el-form
                    :inline="true"
                    ref="form"
                    :model="sysForm"
                    label-width="120px"
                >
                    <el-form-item label="风盘型号：">
                        <el-input v-model="sysForm.type"></el-input>
                    </el-form-item>
                    <br />
                    <el-form-item label="高档制热功率：">
                        <el-input v-model="sysForm.heatHigh"></el-input>
                    </el-form-item>
                    <el-form-item label="中档制热功率：">
                        <el-input v-model="sysForm.heatMid"></el-input>
                    </el-form-item>
                    <el-form-item label="低档制热功率：">
                        <el-input v-model="sysForm.heatLow"></el-input>
                    </el-form-item>
                    <el-form-item label="高档制冷功率：">
                        <el-input v-model="sysForm.coldHigh"></el-input>
                    </el-form-item>
                    <el-form-item label="中档制冷功率：">
                        <el-input v-model="sysForm.coldMid"></el-input>
                    </el-form-item>
                    <el-form-item label="低档制冷功率：">
                        <el-input v-model="sysForm.coldLow"></el-input>
                    </el-form-item>
                </el-form>
                <el-button type="primary" @click="registerClick"
                    >注册</el-button
                >
            </el-card>-->
            <zy-table :tbHead="tbHead" :tbBody="tbBody" highlightCurrentRow>
                <el-table-column slot="column-after" label="操作">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleEditUserClick(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </zy-table>
            <zy-page
                class="search-site-page mt-1"
                @current-change="handlePageCurrentChange"
                @size-change="handlePageSizeChange"
                :pageModel="pageModel"
                :total="total"
                :pageSizes="[15, 20, 50, 300]"
            ></zy-page>
        </el-card>

        <el-dialog
            title="编辑"
            :visible.sync="editUserDialogVisible"
            width="400px"
        >
            <el-form
                :model="editUserDialogModel"
                class="edit-user-dialog"
                label-width="90px"
                :inline="true"
            >
                <el-form-item label="名称：">
                    <el-input
                        v-model="editUserDialogModel.name"
                        :disabled="true"
                    ></el-input>
                </el-form-item>

                <el-form-item label="功率：">
                    <el-input v-model="editUserDialogModel.price"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editUserDialogVisible = false">
                    取 消
                </el-button>
                <el-button type="primary" @click="handleEditUserEnterClick">
                    确 定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ZyTable from '@/components/ZyTable/index'
import ZyPage from '../../../mixins/ZyPage'
export default {
    components: { ZyTable },
    mixins: [ZyPage],
    data() {
        return {
            pice: '0.568',
            // 设置Model
            searchModel: {},
            // 切换季节
            seasonModel: '',
            season: '',
            switchSeason: [
                {
                    value: '制冷',
                    label: '制冷'
                },
                {
                    value: '制热',
                    label: '制热'
                }
            ],

            // 注册表单
            sysForm: {},
            // 注册表格
            tbHead: [
                {
                    prop: 'id',
                    label: 'ID'
                },
                {
                    prop: 'name',
                    label: '名称'
                },
                {
                    prop: 'price',
                    label: '功率'
                }
            ],
            tbBody: [],
            // 编辑用户弹窗
            editUserDialogVisible: false,

            // 编辑用户model
            editUserDialogModel: {}
        }
    },
    created() {
        this.getTabData()
        //this.getPice()
        this.getSeason()
    },
    methods: {
        // 显示当前单价
        //async getPice() {
        //    const res = await this.$api.system.getPice()
        //    this.pice = res.data.price
        //},

        // 显示当前切换模式
        async getSeason() {
            const res = await this.$api.system.getSeason()
            this.season = res.data.season
        },

        // 修改单价
        async setupBtn() {
            this.$confirm('是否确认修改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    var strcookie = document.cookie //获取cookie字符串
                    var arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    let tokenK = ''
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == 'token') {
                            tokenK = arr[1]
                        }
                    }

                    const newPrice = this.searchModel.unitprice.toString()
                    const operator = tokenK
                    const res = await this.$api.system.getPrice(
                        newPrice,
                        operator
                    )
                    // this.searchModel.unitprice = res
                    this.$message({
                        type: 'success',
                        message: res.data
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
        },

        // 注册
        async registerClick() {
            this.$confirm('是否确认注册信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const res = await this.$api.system.registerData(
                        this.sysForm
                    )

                    this.$message({
                        type: 'success',
                        message: res.data
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消注册!'
                    })
                })
        },

        // 修改模式
        async switchBtn() {
            this.$confirm('是否确认修改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    var strcookie = document.cookie //获取cookie字符串
                    var arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    let tokenK = ''
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == 'token') {
                            tokenK = arr[1]
                        }
                    }

                    const setSeason = this.seasonModel
                    const operator = tokenK
                    const res = await this.$api.system.getSeasons(
                        setSeason,
                        operator
                    )
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
        },

        // 获取注册表格信息
        async getTabData() {
            const res = await this.$api.system.getTabData({})

            this.tbBody = res.data
            if (res.code != 200) return this.$message.error('获取数据失败!')
        },

        // 点击编辑用户
        handleEditUserClick(row) {
            this.editUserDialogVisible = true
            this.editUserDialogModel = row
        },

        // 确定编辑用户
        async handleEditUserEnterClick() {
            this.$confirm('是否确认修改信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const strcookie = document.cookie //获取cookie字符串
                    const arrcookie = strcookie.split('; ') //分割
                    //遍历匹配
                    let tokenK = ''
                    for (var i = 0; i < arrcookie.length; i++) {
                        var arr = arrcookie[i].split('=')
                        if (arr[0] == 'username') {
                            tokenK = arr[1]
                        }
                    }
                    this.editUserDialogModel.operator = tokenK
                    const res = await this.$api.system.upPrice(
                        this.editUserDialogModel
                    )
                    console.log('res', res)
                    this.$message({
                        type: 'success',
                        message: res.data
                    })
                    // 关闭弹窗
                    this.editUserDialogVisible = false
                    // 重新获取表格
                    setTimeout(() => {
                        this.getTabData()
                    }, 2000)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消修改!'
                    })
                })
        }
    }
}
</script>
